import React from "react"
import Layout from "../components/layout"
import { BodyLarge } from "../components/typography"
import colors from "../styles/colors"
import styled from "styled-components"
import StoreLocator from "../components/store-locator/Store-Locator"

import Seo from "../components/seo"

const Wrapper = styled.div`
  color: ${colors.creamLight};
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.6) 75%
  );
  background-position: bottom;
  background-size: cover;

  display: flex;
  justify-content: center;

  align-items: center;

  > div {
    text-align: center;

    ${BodyLarge} {
      margin-bottom: 24px;
    }

    a {
      margin-top: 40px;
    }
  }
`

export function Head() {
  return <Seo title={"Store Locator"} />
}

export default function GenericTemplate() {
  return (
    <Layout>
      <Wrapper>
        <StoreLocator />
      </Wrapper>
    </Layout>
  )
}
