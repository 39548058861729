import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import breakpoints from "../../styles/breakpoints"
import colors from "../../styles/colors"
import ListComponent from "./List"
import MapComponent from "./Map"

const Wrapper = styled.div`
  color: ${colors.creamLight};

  position: relative;
  display: flex;
  flex-direction: column-reverse;

  width: 100%;

  &:after {
    content: "";

    position: absolute;
    left: 50%;
    bottom: 0;

    transform: translateX(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  @media ${breakpoints.tablet} {
    max-width: 1440px;
    margin: 0 auto;

    display: grid;
    grid-template-columns: 1fr 1fr;

    flex-direction: row;
    min-height: 880px;
  }
`

const StoreListWrapper = styled.div`
  position: relative;

  padding: 0 24px;

  &:after {
    content: "";

    position: absolute;
    left: -24px;
    bottom: 0;

    transform: translateY(-50%);

    height: 1px;
    width: 100vw;

    background: ${colors.creamLight};
  }

  text-align: center;

  @media ${breakpoints.tablet} {
    padding: 40px 24px;

    border: 0;

    border-right: 1px solid ${colors.creamLight};

    &:after {
      display: none;
    }
  }

  @media ${breakpoints.laptop} {
    padding: 40px;
  }
`

const StoreMapWrapper = styled.div`
  padding: 40px 24px;
  max-height: 800px;
  min-height: 410px;

  @media ${breakpoints.tablet} {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media ${breakpoints.laptop} {
    padding: 40px;
  }
`

export default function StoreLocator() {
  const [selectedPlace, setSelectedPlace] = useState({})

  const data = useStaticQuery(graphql`
    query MyQuery {
      allContentfulStores {
        nodes {
          address {
            raw
          }
          location {
            lat
            lon
          }
          name
          type
        }
      }
    }
  `)

  const places = data.allContentfulStores.nodes

  function handlePlaceClick(place) {
    console.log(place.name)
    setSelectedPlace(place)
  }

  return (
    <Wrapper>
      <StoreListWrapper>
        <ListComponent places={places} handlePlaceClick={handlePlaceClick} />
      </StoreListWrapper>
      <StoreMapWrapper>
        <MapComponent places={places} selectedPlace={selectedPlace} />
      </StoreMapWrapper>
    </Wrapper>
  )
}
